.hr-check-line li {
  color: #fff;
  font-size: 18px;
  margin-bottom: 8px;
}

.hr-check-line ._icon img {
  width: 20px;
  height: auto;
  margin-right: 5px;
}

.hr-check-line ._icon {
  display: inline-block;
}
