.cart-page-item ._img_wrap {
  width: 100px;
  height: 100px;
  display: inline-block;
  /* aspect-ratio: 1/1; */
}

.cart-page-item ._img_wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
