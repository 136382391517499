.review-card ._avatar_wrap {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f6f6f6;
  background-position: center;
  background-size: cover;
}

.review-card ._avatar_wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.review-card {
  border: 3px solid var(--light-accent-color) !important;
  border-radius: 8px;
}

.review-card .card-header {
  border-bottom: 2px dashed var(--primary-color);
}
