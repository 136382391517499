.photo-frame {
  border: 20px solid var(--primary-color);
  transform: rotate(-12deg);
}

.photo-frame img {
  transform: rotate(12deg);
  border: 10px solid #fff;
}

.photo-frame:hover {
  transform: rotate(0deg);
}

.photo-frame:hover img {
  transform: rotate(0deg);
}
