.search-bar ._search-field {
  border-radius: 24px;
  width: 450px;
}

.search-bar ._search_submit {
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: brightness(90%);
}

.search-bar ._result {
  position: absolute;
  top: 80%;
  height: 100%;
  background: #fff;
  width: 450px;
}
