._right_menu .signup-link {
  border: 1px solid var(--primary-color);
  font-family: primary-font;
  font-weight: 600;
  padding: 0.5em 1em;
  font-size: 0.95em;
  color: var(--primary-color);
}

._right_menu .nav-link:hover {
  color: var(--primary-color);
}

._right_menu .nav-link.nav-link {
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

._right_menu .signup-link:hover {
  background-color: #fff;
  background-color: var(--light-accent-color);
}

.navbar.position-sticky {
  top: 0;
  left: 0;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 99;
}

/* .category-dropdown {
  border-radius: 0;
  width: 300px;
  left: 50% !important;
  transform: translateX(-50%);
  z-index: 9999 !important;
} */

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #fff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.03);
}

._right_menu .nav-link.active {
  color: var(--primary-color);
}
