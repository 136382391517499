.image-card .card {
  border-radius: 8px;
  border: 2px solid #e8eef7;
}

.image-card ._avtar_wrap {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  border: 5px solid #fff;
}

.image-card .card::after {
  position: absolute;
  top: 0;
  left: 0;
  height: 25%;
  content: "";
  width: 100%;
  background-color: var(--primary-color);
  transition: 300ms ease-in;
  z-index: -1;
}

.image-card:hover .card::after {
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
  height: 100%;
}

.image-card:hover .circle-btn {
  border-color: #fff;
}

.image-card:hover .circle-btn svg {
  color: #fff !important;
}

.image-card .circle-btn {
  background-color: transparent !important;
}

.image-card .circle-btn:hover {
  border: 2px solid #fff !important;
}

.image-card:hover .card-title,
.image-card:hover .card-text {
  color: #fff;
}
