.mobile-header ._menu_icon {
  width: 35px;
}

.mobile-header .mobile-nav-wrap {
  height: 100vh;

  background: rgb(240, 250, 255);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  max-width: 0;
  height: 100vh;
  overflow: hidden;
  z-index: 9;
}

.mobile-header .mobile-nav-wrap.active {
  max-width: 100%;
}

.list {
  list-style: none;
}

.mobile-header .list a {
  padding: 10px 0px;
  color: #000;
  font-size: 18px;
}

.mobile-header .list a.active {
  color: var(--primary-color);
  font-weight: 500;
}

.mobile-nav-wrap ._top {
  box-shadow: 0px 3px 3px rgba(44, 47, 123, 0.025);
}

.mobile-nav-wrap ._bottom {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.mobile-nav-wrap #menu-close-btn img {
  width: 30px;
  height: 30px;
}
