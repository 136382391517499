.help-tip {
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--primary-color);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
