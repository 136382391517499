#cart{
    top:100%;
    left: 0;
    transform: translateX(-60%);
    min-width: 350px;
    border-radius: 8px;
}

.cart-item ._img_wrap{
    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 8px;
}

.cart-item ._img_wrap img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cart-item ._item_title{
    line-height: 1.1 !important;
}

.cart_btn .badge{
    right: 0;
    top: -8px;
    width: 18px;
    height:18px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 500;
}