:root {
  --primary-color: #0056d2;
  --dark-primary-color: #014fbe;
  --darken-color: #0f1114;
  --light-accent-color: #f3f8ff;
  --lightgrey: #f5f5f5;
}

* {
  transition: 300ms ease-in;
  line-height: normal !important;
}

body {
  background-color: #fff;
  font-size: 16px;
}

@font-face {
  src: url("/public/fonts/source-sans-pro.light.ttf") format("truetype");
  font-family: primary-font;
  font-weight: 300;
}

@font-face {
  src: url("/public/fonts/source-sans-pro.regular.ttf") format("truetype");
  font-family: primary-font;
  font-weight: 400;
}

@font-face {
  src: url("/public//fonts/source-sans-pro.semibold.ttf");
  font-family: primary-font;
  font-weight: 500;
}
@font-face {
  src: url("/public//fonts/source-sans-pro.bold.ttf");
  font-family: primary-font;
  font-weight: 600;
}
@font-face {
  src: url("/public/fonts/source-sans-pro.black.ttf");
  font-family: primary-font;
  font-weight: 700;
}

.bg-darken {
  background-color: var(--darken-color);
}

.bg-primary {
  background-color: var(--primary-color);
}

.bg-lightgrey {
  background-color: var(--lightgrey);
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-lightaccent {
  background-color: var(--light-accent-color);
}

body {
  font-family: primary-font;
  font-weight: 400;
}

.form-control,
.form-select {
  padding: 0.6rem 0.8rem;
}

[type="file"].form-control::file-selector-button {
  padding: 0.6rem 1rem;
}

.form-control,
.form-select {
  padding: 0.6rem 1rem;
  border-radius: 4px;
  height: 46px;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.form-control:hover {
  border-color: var(--primary-color);
}

.form-label {
  font-weight: 500;
}

/* [type="file"].form-control {
  padding: 0;
} */

.modal-content {
  border-radius: 6px;
}

.modal-title {
  font-weight: 400;
}

.modal-dialog .btn-close {
  transform: translate(0px, -60%);
}

.btn-loader {
  width: 15px;
  height: 15px;

  font-size: 12px;
  margin: 0;
}

.alert {
  border-radius: 4px;
}

.rounded-pill {
  padding: 10px 24px;
  margin: 10px;
  font-size: 18px;
  font-weight: 400;
  white-space: pre-wrap;
}

.icon-card ._icon_wrap img {
  max-width: 60px;
}

.top-50p {
  top: 50%;
}

.instructor-benefits {
  margin-bottom: -12em;
}

.pos-top-right {
  top: 0;
  right: 0;
}

.circle-btn {
  /* border: 1px solid white; */
  border-radius: 50% !important;
  width: 50px;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: var(--primary-color) !important;
}

.circle-btn svg {
  color: var(--primary-color) !important;
}

.circle-btn:hover {
  border-color: #fff;
}

/* temporary css starts */

.top_bar li a {
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
}

.top_bar li a:hover {
  background-color: #fff;
  color: var(--darken-color);
  font-weight: 500;
}

.search-bar ._search-field {
  border-radius: 24px;
  width: 450px;
}

.text-sm,
.text-sm * {
  font-size: 15px;
}

.link {
  color: blue;
}

.search-bar ._search_submit {
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: brightness(90%);
}

._right_menu .signup-link {
  border: 1px solid var(--primary-color);
  font-family: primary-font;
  font-weight: 600;
  padding: 0.5em 1em;
  font-size: 0.95em;
  color: var(--primary-color);
}

._right_menu .nav-link:hover {
  color: var(--primary-color);
}

._right_menu .nav-link.nav-link {
  padding-right: 0.8rem;
  padding-left: 0.8rem;
}

._right_menu .signup-link:hover {
  background-color: #fff;
  background-color: var(--light-accent-color);
}

.navbar.position-sticky {
  top: 0;
  left: 0;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 99;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
  letter-spacing: 1px;
  color: var(--darken-color);
}

h1,
.h1 {
  font-size: 3em;
}

h3,
.h3 {
  font-size: 1.8em;
}

h4,
.h4 {
  font-size: 1.7em;
}

h5,
.h5 {
  font-size: 1.3em;
}

.hero {
  background-color: #0056d2 !important;
}

p {
  font-size: 1em;
  line-height: 1.5 !important;
}

.theme-btn {
  padding: 0.8em 1.5em;
  border-radius: 0.25rem;
  font-weight: 500;
}

.btn-light,
.btn-light:hover {
  color: var(--primary-color);
}

.btn-light:hover {
  filter: brightness(95%);
}

.btn-outline-light:hover {
  background-color: #0048b0;
  color: #fff;
}

section,
.ct-py {
  padding-top: 4em;
  padding-bottom: 4em;
}

.pb-2x {
  padding-bottom: 8em;
}

.pt-2x {
  padding-top: 8em;
}

.invert-img {
  filter: invert(1);
}

.sec-sub-title {
  font-size: 1.1em;
  font-weight: 500;
  margin-bottom: 0.4em;
}

.sec-title,
h2 {
  font-size: 2.7em;
  font-weight: 400;
}

.sec-short-desc {
  font-size: 1.2em;
  font-weight: 400;
}

.sec-title-sm {
  font-size: 2em;
}

/* .cc-style-1 {
  border-radius: 16px;
  padding: 10px 10px;
  border: 2px solid #e8eef7;
  max-height: 370px;
  color: var(--darken-color);
}

.cc-style-1 * {
  color: var(--darken-color);
}

.cc-style-1 .img-wrap {
  border-radius: 12px;
  overflow: hidden;
  height: 160px;
}

.cc-style-1 .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  padding: 0;
  outline: 0;
}

.cc-style-1 ._partner ._partner_icon_wrap {
  width: 25px;
  height: 25px;
  border: 1px solid var(--lightgrey);
  border-radius: 8px;
  margin-right: 8px;
}

.cc-style-1 ._partner ._partner_name {
  color: #919191;
  font-size: 15px;
}



.cc-style-1 .card-title {
  font-size: 1em;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cc-style-1 ._highlight {
  font-size: 15px;
  color: var(--primary-color);
}

.cc-style-1 ._highlight ._highligt_icon {
  margin-right: 10px;
}

.cc-style-1 ._highligt_icon path,
.cc-style-1 ._highligt_title,
.cc-style-1 ._highligt_title span {
  color: var(--primary-color) !important;
  font-size: 18px;
  font-weight: 500;
}

.cc-style-1 ._category {
  font-size: 15px;
  margin-top: 15px;
  color: #5b6780;
}

.cc-style-1 ._badge {
  right: 10px;
  top: 10px;
} */

.btn-primary {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-primary:hover {
  background-color: var(--dark-primary-color) !important;
}

.btn-outline-primary:hover {
  border: 1px solid var(--primary-color) !important;
  background-color: var(--light-accent-color) !important;
  color: var(--primary-color);
}

.theme-btn ._icon {
  margin-left: 5px;
}

.bg-style-1 {
  background-image: url("/public//img/backgrounds/bg-1.png");
  background-repeat: no-repeat;
  background-position: right bottom;
}

.tile-style-1 {
  box-shadow: rgba(31, 31, 31, 0.12) 0px 1px 6px,
    rgba(31, 31, 31, 0.12) 0px 1px 4px;
  transition: 450ms cubic-bezier(0.23, 1, 0.32, 1);
}

.tile-style-1 ._img_wrap {
  width: 80px;
  border-radius: 4px;
  overflow: hidden;
  background-color: var(--light-accent-color);
}

.tile-style-1 ._img_wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tile-style-1 .card-title {
  font-size: 1em;
}

.tile-style-1:hover {
  box-shadow: rgba(31, 31, 31, 0.16) 0px 3px 10px,
    rgba(31, 31, 31, 0.23) 0px 3px 10px;
}

.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}

.sep {
  display: block;
  width: 70px;
  height: 2px;
  background: var(--primary-color);
}

.list-group.non-style a {
  border: none;
  font-size: 0.95em;
  padding: 7px 0;
  background-color: transparent;
}

.list-group.non-style a:hover {
  background-color: inherit;
}

footer {
  background-color: var(--lightgrey);
  padding-top: 5em;
  padding-bottom: 5em;
}

/* 
  hover effects
*/

.scale-on-hover {
  /* transition: 500ms ease-in; */
  transition: all 0.3s cubic-bezier(0, 0, 0.5, 1);
}

.scale-on-hover:hover {
  /* transform: scale(1.1); */
  transform: scale3d(1.03, 1.03, 1.08) translate3d(0.1rem, -0.25rem, 20rem);
}

.shadow-on-hover:hover {
  box-shadow: 0 0 40px -8px rgb(0 0 0 / 16%), 0 0 24px -16px rgb(0 0 0 / 16%);
}

.social-icons {
  height: 36px;
  width: 36px;
  padding: 5px;
  font-size: 18px;
  display: inline-block;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--darken-color);
  color: #fff;
}

/* .category-dropdown {
  border-radius: 0;
  width: 300px;
  left: 50% !important;
  transform: translateX(-50%);
  z-index: 9999 !important;
} */

.dropdown .dropdown-menu {
  border-radius: 0;
  width: 300px;
  left: 50% !important;
  transform: translate(-50%, 56px) !important;
  z-index: 9999 !important;
  background-color: var(--light-accent-color);
}

.dropdown-item:focus,
.dropdown-item:hover {
  background-color: #fff;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.03);
}

.vh-70 {
  height: 70vh;
}

.white-space-nowrap {
  white-space: nowrap;
}

/* responsive starts */

.list {
  list-style: none;
}

.mobile-header .list a {
  padding: 10px 0px;
  color: var(--blue);
}

.mobile-nav-wrap ._top {
  box-shadow: 0px 3px 3px rgba(44, 47, 123, 0.025);
}

.mobile-nav-wrap ._bottom {
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.dropdown-menu.show.dropdown-menu-end {
  transition: none;
}

@media (min-width: 1200px) {
}

@media (max-width: 1200px) {
}

@media (max-width: 991px) {
  /* mobile menu starts */

  #header-logo-mobile {
    max-width: 50px;
  }

  /* mobile menu ends */

  h1,
  .h1 {
    font-size: 3em;
  }

  .sec-title,
  h2 {
    font-size: 2.8em;
    font-weight: 500;
  }

  h3,
  .h3 {
    font-size: 2.4em;
  }

  h4,
  .h4 {
    font-size: 2.1em;
  }

  h5,
  .h5 {
    font-size: 1.8em;
  }

  .course_title {
    font-size: 1.87em;
  }
}

@media (max-width: 767px) {
  /* mobile menu starts */

  #header-logo-mobile {
    max-width: 70px;
  }

  /* mobile menu ends */

  h1,
  .h1 {
    font-size: 2em;
  }

  .sec-title,
  h2 {
    font-size: 1.8em;
    font-weight: 500;
  }

  h3,
  .h3 {
    font-size: 1.5em;
  }

  h4,
  .h4 {
    font-size: 1.3em;
  }

  h5,
  .h5 {
    font-size: 1.2em;
  }

  footer {
    padding-top: 3em;
    padding-bottom: 1em;
  }

  .partners .partner-logo {
    width: 33.33%;
  }

  .sec-sub-title {
    font-size: 1.3em;
    margin-bottom: 0.6em;
  }

  .sec-title,
  h2 {
    margin-bottom: 0.1em;
  }

  .cc-style-1 {
    max-height: 410px;
  }

  .cc-style-1 .img-wrap {
    border-radius: 12px;
    overflow: hidden;
    height: 200px;
  }

  .sec-title-sm {
    font-size: 1.7em;
  }

  .testimonial-card ._desc {
    font-size: 1em;
  }

  .all-courses-section td,
  th {
    white-space: nowrap;
  }

  .lead {
    font-size: 1.01rem;
  }

  .course_title {
    font-size: 1.7em;
  }

  .wishlist-btn {
    width: 40px !important;
    height: 40px !important;
    border-radius: 50%;
    font-size: 18px;
    padding: 0;
  }

  .payble-totals {
    font-size: 2em;
  }

  .left-overlap {
    margin-left: 0 !important;
    border: none !important;
  }

  .login-modal .modal-body {
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
  }

  .photo-frame {
    transform: rotate(-0) !important;
  }

  .photo-frame img {
    transform: rotate(0deg) !important;
    border: 3px solid #fff !important;
  }

  .accordion-item h5 {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .partners .partner-logo {
    width: 50%;
  }

  .newsletter ._label {
    display: none;
  }
}

/* responsive ends */

/* temporary css ends */

.line-height-normal {
  line-height: 2 !important;
}

.lottiefile-wrap canvas {
  width: 50% !important;
}

.type-none {
  list-style-type: none;
}

.inifit-scroll__wrap {
  overflow: unset !important;
}

.cart_btn:hover #cart {
  display: block !important;
}

.primary-filled-white-icon-btn {
  width: 60px;
  height: 60px;
  border-radius: 100%;
}

.position-center-center {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.applied-coupon {
  border: 2px dashed var(--primary-color);
}

.vertical-middle {
  vertical-align: middle;
}

.wishlist-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

.wishlist-btn-wrap {
  right: 10%;
  top: 10%;
}
.home_marquee a {
  font-weight: 600;
  color: var(--dark-primary-color);
  font-size: 24px;
  margin: 0 8px;
}

.hero img {
  max-width: 80%;
}

@media (min-width: 1200px) and (max-width: 1300px) {
  .search-bar ._search-field {
    width: 360px !important;
  }
}

@media (min-width: 1301px) and (max-width: 1399px) {
  .search-bar ._search-field {
    width: 340px !important;
  }
}
