.mobile-search-bar {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.mobile-search-bar.active {
  display: block !important;
}

.mobile-search-bar ._search-field {
  border-radius: 24px;
}

.mobile-search-bar form {
  width: 450px;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.mobile-search-bar ._search_submit {
  right: 16px;
  top: 50%;
  transform: translate(0, -50%);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  filter: brightness(90%);
}

.mobile-search-bar ._result {
  /* position: absolute; */
  /* top: 80%; */
  height: 100%;
  background: #fff;
  width: 100%;
}

.mobile-search-bar #_search-close-btn img {
  width: 45px;
  height: 45px;
}
