.testimonial-card .avtar {
  width: 200px;
  height: 200px;
  overflow: hidden;
}
.testimonial-card .avtar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.testimonial-card .card-title {
  color: var(--dark-primary-color);
}

.testimonial-card ._desc {
  font-size: 1em;
  font-weight: 400;
}

.testimonial-card:last-child .card-body {
  padding-bottom: 0;
}
