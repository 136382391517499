.top_bar li a {
  color: #fff;
  padding-top: 8px;
  padding-bottom: 8px;
}

.top_bar li a:hover {
  background-color: #fff;
  color: var(--darken-color);
  font-weight: 500;
}
