.scrollable-horizontal-menu a {
  border-bottom: 2px solid transparent;
  font-size: 18px;
  white-space: nowrap;
}

.scrollable-horizontal-menu a.active {
  border-color: var(--primary-color);
  color: var(--primary-color) !important;
}

.navbar.position-sticky {
  box-shadow: none;
}
