.update-profile ._avatar {
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 50%;
  display: inline-block;
}

.update-profile ._avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
