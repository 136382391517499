.blog-card ._img_wrap {
  height: 150px;
  display: block;
}

.blog-card ._img_wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-card .card-title {
  font-size: 1em;
  margin-top: 10px;

  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  font-weight: 500;
}
