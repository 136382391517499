.course-progress {
  width: 40px;
  display: inline-block;
}

.CircularProgressbar .CircularProgressbar-text {
  font-size: 28px;
}

.CircularProgressbar {
  width: 40px;
}

.course-contents .collapsed {
  background: var(--lightgrey);
}

.watch-course-additional-details .nav-tabs .nav-item.show .nav-link,
.watch-course-additional-details .nav-tabs .nav-link.active {
  border: none;
  outline: none;
  border-bottom: 4px solid var(--primary-color);
}

.watch-course-additional-details .nav-tabs .nav-item .nav-link:focus-visible,
.watch-course-additional-details .nav-tabs .nav-link:focus-visible,
.watch-course-additional-details .nav-tabs .nav-item .nav-link:hover,
.watch-course-additional-details .nav-tabs .nav-link:hover {
  outline: none;
  border-left: none;
  border-right: none;
  border-top: none;
}


.lesson-loader{
  height:300px;
}