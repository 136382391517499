.profile-dropdown ._avtar_wrap{
    display: inline-block;
    width:40px;
    height:40px;
    border-radius: 50%;
    overflow: hidden;
}

.profile-dropdown ._avtar_wrap img{
    width:100%;
    height:100%;
    object-fit: cover;
}