.ct-pages .left-overlap {
  margin-left: -20%;
}
.ct-pages .size-18 {
  font-size: 18px !important;
}
.ct-pages p {
  text-align: justify;
  /* font-family: Georgia, serif; */
}
.ct-pages h3 {
  font-size: 24px !important;
  color: #000;
  font-weight: 600;
}
