.cc-style-1 {
  border-radius: 16px;
  padding: 10px 10px;
  border: 2px solid #e8eef7;
  max-height: 370px;
  color: var(--darken-color);
}

.cc-style-1 * {
  color: var(--darken-color);
}

.cc-style-1 .img-wrap {
  border-radius: 12px;
  overflow: hidden;
  height: 160px;
}

.cc-style-1 .img-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  margin: 0;
  padding: 0;
  outline: 0;
}

.cc-style-1 ._partner ._partner_icon_wrap {
  width: 25px;
  height: 25px;
  border: 1px solid var(--lightgrey);
  border-radius: 8px;
  margin-right: 8px;
}

.cc-style-1 ._partner ._partner_name {
  color: #919191;
  font-size: 15px;
}

.cc-style-1 .card-title {
  font-size: 1em;
  margin-top: 10px;
  margin-bottom: 20px;
  height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.cc-style-1 ._highlight {
  font-size: 15px;
  color: var(--primary-color);
}

.cc-style-1 ._highlight ._highligt_icon {
  margin-right: 10px;
}

.cc-style-1 ._highligt_icon path,
.cc-style-1 ._highligt_title,
.cc-style-1 ._highligt_title span {
  color: var(--primary-color) !important;
  font-size: 18px;
  font-weight: 500;
}

.cc-style-1 ._category {
  font-size: 15px;
  margin-top: 15px;
  color: #5b6780;
}

.cc-style-1 ._badge {
  right: 10px;
  top: 10px;
}
